import axios from "axios"
import { hashHandler } from "../../utility/functions"
import { clearStorageAll, fetchUserToken } from "../../utility/storage"
import { apiStatusText, API_AREA_VALUE, baseURL } from "./config"


export const postRequest = (params) => {
    return new Promise(async (success, reject) => {
        try {

            if (params?.contentType !== 'multipart/form-data') {
                params = {
                    url: params.url,
                    payload: {
                        ...params.payload,
                        area: API_AREA_VALUE
                        // reference: userAgentInfo()
                    }
                }
            }

            const endPoint = params.url.split('?')[0].split('/').pop()

            const userToken = fetchUserToken()

            // console.log('userToken', userToken)

            await axios.request({
                validateStatus: (status) => status,
                method: 'POST',
                url: `${baseURL}${params.url}?area=${API_AREA_VALUE}`,
                data: params?.payload,
                headers:
                {
                    Authorization: userToken ? `Bearer ${userToken}` : '',
                    requestHash: hashHandler(endPoint),
                    "Content-Type": (params?.contentType) ? params.contentType : "application/json",
                    accept: "application/json",
                    "Access-Control-Allow-Origin": ["https://*.xettle.io"],
                    withCredentials: true,
                    'X-Requested-With': 'XMLHttpRequest'
                    // 'api-key-1': api_key_1,
                    // 'api-key-2': api_key_2
                }
            }).then((response) => {

                // console.log('postRequest', params.url, response)

                let isSuccess = true

                switch (response.status) {
                    case 200:
                        if (response.data.status === apiStatusText.unauthorized) {
                            isSuccess = false
                            clearStorageAll()
                            window.location.replace('/')
                        }
                        break

                    case 401:
                        isSuccess = false
                        clearStorageAll()
                        window.location.replace('/')
                        break
                }

                if (isSuccess) {
                    success(response)
                }

            }).catch((error) => {
                console.log('getRequest: error', error)
                reject(error)
            })
        } catch (error) {
            console.log('getRequest: error', error)
            reject(error)
        }
    })
}


export const getRequest = (params) => {
    return new Promise(async (success, reject) => {
        try {

            if (params?.payload?.area === undefined || params?.payload?.area === null || params?.payload?.area === '') {
                params = { url: params.url, payload: { ...params.payload, area: API_AREA_VALUE } }
            }

            const endPoint = params.url.split('?')[0].split('/').pop()

            const userToken = fetchUserToken()

            // console.log('userToken', userToken)

            await axios.request({
                validateStatus: (status) => status,
                method: 'GET',
                url: `${baseURL}${params.url}`,
                params: (params?.payload),
                headers:
                {
                    Authorization: userToken ? `Bearer ${userToken}` : '',
                    requestHash: hashHandler(endPoint),
                    "Content-Type": "application/json",
                    accept: "application/json",
                    "Access-Control-Allow-Origin": ["https://*.xettle.io"],
                    withCredentials: true,
                    'X-Requested-With': 'XMLHttpRequest'
                    // 'api-key-1': api_key_1,
                    // 'api-key-2': api_key_2
                }
            }).then((response) => {
                // console.log('getRequest', params.url, response)

                let isSuccess = true

                switch (response.status) {
                    case 200:
                        if (response.data.status === apiStatusText.unauthorized) {
                            isSuccess = false
                            clearStorageAll()
                            window.location.replace('/')
                        }
                        break

                    case 401:
                        isSuccess = false
                        clearStorageAll()
                        window.location.replace('/')
                        break
                }

                if (isSuccess) {
                    success(response)
                }

            }).catch((reason) => {
                console.log('getRequest: error', reason)
                reject(error)
            })

        } catch (error) {
            console.log('getRequest: try_catch: ', error)
            reject(error)
        }
    })
}