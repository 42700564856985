import validationSuite from '@src/assets/images/business/verification.svg'
// import ocr from '@src/assets/images/business/ocr.png'
import bbps from '@src/assets/images/business/bbps_logo.png'
import recharge from '@src/assets/images/business/recharge.svg'
import aadhaarPay from '@src/assets/images/business/aeps.svg'
import primaryWallet from '@src/assets/images/business/wallet.svg'
import dmtIcon from '@src/assets/images/business/dmt.svg'
import insurance from '@src/assets/images/business/insurance.svg'
import matmIcon from '@src/assets/images/icons/atm-9.png'

import proAadhaarIcon from '@src/assets/images/product_icons/banking.png'
import proDmtIcon from '@src/assets/images/product_icons/money-transfer.png'
import proRechargeIcon from '@src/assets/images/product_icons/payments.png'
import proVerificationIcon from '@src/assets/images/product_icons/verification.png'
import proMicroAtmIcon from '@src/assets/images/product_icons/micro-atm.png'
import proInsuranceIcon from '@src/assets/images/product_icons/insurance.png'
import proPanService from '@src/assets/images/product_icons/pan_service.png'

export const baseURL = process.env.REACT_APP_BASE_URL

export const oldDashUrl = process.env.REACT_APP_DASH_URL
export const NOCAPTCHA_SITEKEY = process.env.REACT_APP_NOCAPTCHA
export const API_AREA_VALUE = 'web'
export const API_DOC_URL = 'https://docs.xettle.io'

export const httpConfig = {
    baseURL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
        // 'Access-Control-Allow-Origin': '*'
        // Authorization: authToken ? `Bearer ${authToken}` : ''
    },
    withCredentials: true
}


export const apiUrls = {
    sanctum: '/sanctum/csrf-cookie',
    login: '/api/spa/v1/login',
    loginSubmitOtp: '/api/spa/v1/verify-login-otp',
    loginOldPlatform: '/api/spa/v1/login-old-platform',
    logout: '/api/spa/v1/logout',
    register: '/api/spa/v1/register',
    serviceList: '/api/spa/v1/serviceList',
    checkServiceStatus: '/api/spa/v1/check/service-status',
    onboardingSteps: {
        step_1: "/api/spa/v1/onboard/business-overview",
        step_2: "/api/spa/v1/onboard/business-details",
        step_3: "/api/spa/v1/onboard/business-info",
        videoKyc: "/api/spa/v1/onboard/video-kyc",
        verifyPan: "/api/spa/v1/onboard/verify-pan",
        verifyPanStatus: "/api/spa/v1/onboard/verify-pan/status",
        verifyAadhaar: "/api/spa/v1/onboard/verify-aadhaar",
        verifyAadhaarRoot: "/api/spa/v1/onboard/verify-aadhaar-root",
        verifyAadhaarOtp: "/api/spa/v1/onboard/verify-aadhaar-otp"
    },
    verification: {
        resendEmail: "/api/spa/v1/resend/verification-email"
        // resendEmail: '/email/verification-notification/'
    },
    forgetPassword: {
        submitEmail: '/api/spa/v1/forgot-password',
        submitMobile: '/api/spa/v1/send-otp',
        submitOtp: '/api/spa/v1/reset-password',
        redendOtp: '/api/spa/v1/resend-otp'
    },
    request: {
        service: '/api/spa/v1/activateService',
        addIp: '/api/spa/v1/add-ip',
        addSdkKey: '/api/spa/v1/sdk-keys',
        addApiKey: '/api/spa/v1/generateKeys',
        deleteIp: '/api/spa/v1/delete-ip',
        webhook: '/api/spa/v1/webhook'
    },
    requestMoney: {
        init: '/api/spa/v1/request/money',
        allTxns: '/api/spa/v1/request/money/all-txn'
    },
    ocr: {
        dashboardStats: '/api/spa/v1/ocr/dashboard',
        datatableTxn: '/api/spa/v1/ocr/transactions',
        recentTxn: '/api/spa/v1/ocr/recent-transactions'
    },
    recharge: {
        dashboardStats: '/api/spa/v1/recharge/dashboard',
        datatableTxn: '/api/spa/v1/recharge/transactions',
        recentTxn: '/api/spa/v1/recharge/recent-transactions',
        dashGraph: '/api/spa/v1/graph/recharge-transaction'
    },
    aeps: {
        merchantList: '/api/spa/v1/apes/merchants',
        transactions: '/api/spa/v1/apes/transactions',
        settlements: '/api/spa/v1/apes/settlements',
        dashboardCountsApi: '/api/spa/v1/apes/counts',
        recentTxn: '/api/spa/v1/apes/recent-transactions',
        graphTxn: '/api/spa/v1/graph/aeps/transaction',
        graphCount: '/api/spa/v1/graph/aeps/txn-counts'

    },
    matm: {
        transactions: '/api/spa/v1/micro-atm/transactions',
        settlements: '/api/spa/v1/micro-atm/settlements',
        dashboardCountsApi: '/api/spa/v1/micro-atm/counts',
        recentTxn: '/api/spa/v1/micro-atm/recent-transactions',
        graphTxn: '/api/spa/v1/graph/matm/transaction',
        graphCount: '/api/spa/v1/graph/matm/txn-counts'

    },
    dmt: {
        merchantList: '/api/spa/v1/dmt/merchants',
        transactions: '/api/spa/v1/dmt/transactions',
        dashboardStats: '/api/spa/v1/dmt/dashboard',
        remitters: '/api/spa/v1/dmt/remitters',
        recentTxn: '/api/spa/v1/dmt/recent-transactions',
        graphTxn: '/api/spa/v1/graph/dmt/transaction'
    },
    pan: {
        merchantList: '/api/spa/v1/panCard/agentdetail',
        transactions: '/api/spa/v1/panCard/transactions',
        dashboardStats: '/api/spa/v1/panCard/dashboard',
        // remitters: '/api/spa/v1/dmt/remitters',
        recentTxn: '/api/spa/v1/panCard/recent-transactions',
        graphTxn: '/api/spa/v1/graph/panCard'
    },
    validationSuite: {
        dashboardStats: '/api/spa/v1/validate/dashboard',
        datatableTxn: '/api/spa/v1/validate/transactions',
        recentTxn: '/api/spa/v1/validate/recent-transactions',
        txnCountGraph: '/api/spa/v1/graph/validate-txn-counts'
    },
    fetch: {
        businessType: '/api/spa/v1/getBusinessTypeList',
        categoryList: '/api/spa/v1/categoryList',
        managerList: '/api/spa/v1/account-manager-list',
        stateList: '/api/spa/v1/states'
    },
    update: {
        profile: '/api/spa/v1/profile',
        bank: '/api/spa/v1/update-bank',
        videoKyc: '/api/spa/v1/ekyc/save',
        password: '/api/spa/v1/user/change-password'
    },
    delete: {
        ipwhitelist: '/api/spa/v1/delete-ip'
    },
    user: {
        profile: '/api/spa/v1/profile',
        bank: '/api/spa/v1/userBankInfos',
        wallet: '/api/spa/v1/userWallet',
        primaryWallet: '/api/spa/v1/user/primary-balance',
        userInfo: '/api/spa/v1/userDetails',
        manager: '/api/spa/v1/user/manager',
        addBankAccount: '/api/spa/v1/update-bank'
    },
    settings: {
        ipwhitelist: '/api/spa/v1/iplist',
        apiKeys: '/api/spa/v1/userServiceKeys',
        sdkKeys: '/api/spa/v1/sdk-keys'
    },
    dashboard: {
        txnCount: '/api/spa/v1/graph/txn-counts',
        validateWallet: '/api/spa/v1/graph/validate-wallet',
        ocrWallet: '/api/spa/v1/graph/ocr-wallet',
        transaction: '/api/spa/v1/transactions/primary-wallet'
    },
    insurance: {
        partner: '/api/spa/v1/insurance/partner',
        redirectUrl: '/redirect'
    },
    recent: {
        walletTransactions: '/api/spa/v1/walletTransactions'
    },
    log: {
        loginActivity: '/api/spa/v1/login-activity'
    },
    autoSttlement: {
        report: '/api/spa/v1/transactions/auto-settlements'
    },
    reportDownload: {
        generateDownloadLink: '/api/spa/v1/download/reports/generate-download-link',
        downloadLink: '/api/spa/v1/open-download/reports/download-link',
        generateFile: '/api/spa/v1/download/reports/generate-file',
        removeExportFile: '/api/spa/v1/download/reports/remove-export-file',
        transaction: '/api/spa/v1/download/reports/transactions'
    }
}


export const statusCode = {
    success: '0x0200',
    failed: '0x0202',
    missing: '0x0203'
}


export const apiStatusCodes = {
    success: '0x0200',
    failed: '0x0202',
    missing: '0x0203',
    exception: '0x0205',
    pending: "0x0206",
    http: {
        success: 200
    }
}


export const apiStatusText = {
    unauthorized: 'UNAUTHORIZED',
    maintenance: 'maintenance'
}


export const appUrls = {
    user: {
        profileSetup: '/profile/setup',
        platform: '/select/platform',
        profile: '/user/profile',
        profileUpdate: '/user/profile/update',
        kycUpdate: '/user/profile/kyc',
        setting: '/user/settings'
    }
}


export const serviceLogo = (serviceId) => {

    switch (serviceId) {
        //aeps
        case 'wallet':
            return { icon: primaryWallet }

        //aeps
        case 'srv_1626077390':
            return {
                icon: aadhaarPay,
                proIcon: proAadhaarIcon,
                desc: "Use our Aadhaar Enabled Payment System (AEPS) payment service that allows you to perform balance enquiry, cash withdrawal and mini statement.",
                features: [
                    "Cash Withdrawal",
                    "Balance Enquiry",
                    "Mini Statement",
                    "Aadhaar Pay"
                ]
            }

        //dmt
        case 'srv_1674209568':
            return {
                icon: dmtIcon,
                proIcon: proDmtIcon,
                // className: 'dmt',
                desc: "Provide low cost money transfer services to your merchants & customers using IMPS/NEFT.",
                features: [
                    "Send via IMPS",
                    "Send via NEFT",
                    "Account Verification"
                ]
            }

        //OCR
        // case 'srv_1663916703':
        //     return { icon: ocr }

        //verification suite
        case 'srv_1652770831':
        case 'srv_1671108401':
            return {
                icon: validationSuite,
                proIcon: proVerificationIcon,
                className: 'recharge',
                desc: "Fetch customer details such as Name, Address, Photograph from the UIDAI database by passing Aadhaar No & OTP.",
                features: [
                    "Scan & Capture KYC",
                    "Video KYC WebKit",
                    "Verify KYC Documents",
                    "Automate Customer Journey"
                ]
            }

        case 'srv_16611613305':
            return { icon: bbps }


        //PAN service
        case 'srv_1681458055':
            return {
                icon: validationSuite,
                proIcon: proPanService,
                desc: "Use our PAN service to generate new PAN, make corrections, and make request for ePAN and physical PAN.",
                features: [
                    "Generate new PAN",
                    "Correction in old PAN",
                    "Request for physical PAN",
                    "Request for ePAN"
                ]
            }

        case 'srv_1626077505':
            return {
                icon: recharge,
                proIcon: proRechargeIcon,
                className: 'recharge',
                desc: "Provide all the bill payments, recharges and top ups services from your app.",
                features: [
                    "Mobile & DTH Recharges",
                    "Electricity Payments",
                    "Credit Card Payments",
                    "All utility bill payments"
                ]
            }

        case 'srv_1680249103':
            //micro ATM
            return {
                icon: matmIcon,
                proIcon: proMicroAtmIcon,
                className: 'dmt',
                desc: "Provide hassle-free method for merchants and customers to receive and make payments from your app.",
                features: [
                    "Cash Withdrawal",
                    "Balance Enquiry"
                ]
            }

        //insurance
        case 'srv_1679139459':
            return {
                icon: insurance,
                proIcon: proInsuranceIcon,
                desc: "Get personalized health insurance advice at your home",
                features: [
                    "Life Insurance",
                    "Health Insurance",
                    "Two Wheeler Insurance",
                    "Term Insurance"
                ]
            }

        //offers
        case 'srv_1688844888':
            return {
                icon: recharge,
                proIcon: proRechargeIcon,
                className: 'recharge',
                desc: "Get the best offers and deals"
                // features: [
                // ]
            }
    }

    return ''
}


//ignore and not show wallet balance card at dashboard
export const ignoreBalanceCard = Array(
    'srv_1679139459'
)