import { generateRequestHash } from "./encdec"
import { fetchIdentifierToken, fetchUserData, fetchUserToken } from "./storage"

export function numberInputHandler(event, key, callback) {
    callback(key, event.target.value.replace(/[^0-9]/g, ''))
}

export const hashHandler = (urlEndPoint) => {

    urlEndPoint = urlEndPoint || ''

    let key = ''
    let uid = ''
    let iv = ''

    if (fetchUserData() && fetchIdentifierToken() && fetchUserToken()) {
        key = fetchIdentifierToken()
        uid = fetchUserData()
        uid = String(uid.accountNumber)
        uid = uid.replaceAll('"', '').substr(-4)
        iv = `${process.env.REACT_APP_IV_II}${uid}`
        // console.log('uid', uid, iv)
    } else {
        key = process.env.REACT_APP_ENC_KEY
        iv = process.env.REACT_APP_IV
    }

    const signStr = `${process.env.REACT_APP_SIGN_I}${urlEndPoint}${process.env.REACT_APP_SIGN_II}${new Date().getTime()}`

    const hashedSign = generateRequestHash(signStr, window.atob(key), iv)

    // console.log('key iv', window.atob(key), iv, urlEndPoint);
    // console.log('hashedSign', hashedSign, signStr)

    return hashedSign
}