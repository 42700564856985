import { decryptor, encryptor } from "./encdec"


export const storeTempData = (key, val) => {
    localStorage.setItem(key, encryptor(val))
}

export const fetchTempData = (key) => {
    try {
        const d = localStorage.getItem(key)
        if (d) {
            return decryptor(d)
        }

        return ''
    } catch (error) {
        localStorage.clear()
        // console.log('storage_log', error)
        return ''
    }
}

export const clearTempData = (key) => {
    localStorage.removeItem(key)
}


export const storeUserToken = (val) => {
    localStorage.setItem('accessToken', encryptor(val))
}

export const fetchUserToken = () => {
    try {
        const d = localStorage.getItem('accessToken')
        if (d) {
            return decryptor(d)
        }

        return ''
    } catch (error) {
        localStorage.clear()
        // console.log('storage_log', error)
        return ''
    }
}


export const storeIdentifierToken = (val) => {
    localStorage.setItem('identifierToken', encryptor(val))
}

export const fetchIdentifierToken = () => {
    try {
        const d = localStorage.getItem('identifierToken')
        if (d) {
            return decryptor(d)
        }

        return ''
    } catch (error) {
        localStorage.clear()
        // console.log('fetchIdentifierToken', error)
        return ''
    }
}


export const storeUserData = (data) => {
    localStorage.setItem('userData', encryptor(JSON.stringify(data)))
}

export const fetchUserData = () => {

    try {
        const d = localStorage.getItem("userData")
        if (d) {
            return JSON.parse(decryptor(d))
        }

        return ''
    } catch (error) {
        localStorage.clear()
        // console.log('fetchUserData', error)
        return ''
    }
}


export const storeUserInfo = (data) => {
    localStorage.setItem('userInfos', encryptor(JSON.stringify(data)))
}


export const fetchUserInfo = () => {
    try {
        const d = localStorage.getItem("userInfos")
        if (d) {
            return JSON.parse(decryptor(d))
        }

        return ''
    } catch (error) {
        localStorage.clear()
        // console.log('storage_log', error)
        return ''
    }
}


export const clearStorageAll = () => {
    localStorage.clear()
    // console.log('clearStorageAll')
}
