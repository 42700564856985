import { createSlice } from "@reduxjs/toolkit"

export const userRegiterSlice = createSlice({
    name: 'userRegiterSlice',
    initialState: {
        emailVerifyPage: null
    },
    reducers: {
        setEmailVerifyPage(state, action) {
            state.emailVerifyPage = action.payload
        }
    }
})


export const userRegiterActions = userRegiterSlice.actions

export default userRegiterSlice.reducer