import { useState, createContext } from "react"
import Spinner from '@components/spinner/Fallback-spinner'


const pageLoaderClass = 'xtl_loader_hide'

const PageLoader = createContext()

const PageLoaderContext = ({ children }) => {
    const [pageLoader, setPageLoader] = useState('')

    return (<PageLoader.Provider value={setPageLoader}>
        <div className={`xtl_loader ${pageLoader}`}>
            <Spinner />
        </div>
        {children}
    </PageLoader.Provider>)
}

export { PageLoader, PageLoaderContext, pageLoaderClass }