const CryptoJS = require("crypto-js")

const AES_KEY = process.env.REACT_APP_AES_KEY
const AES_IV = process.env.REACT_APP_AES_KEY_IV

export const encryptor = text => {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(text), AES_KEY, { iv: AES_IV }).toString()
    return ciphertext
}

export const decryptor = text => {
    const bytes = CryptoJS.AES.decrypt(text, AES_KEY, { iv: AES_IV })
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData
}


export const generateRequestHash = (word, key, iv) => {

    key = CryptoJS.enc.Utf8.parse(key)
    iv = CryptoJS.enc.Utf8.parse(iv)

    let encrypted = CryptoJS.AES.encrypt(word, key, { iv })
    encrypted = encrypted.toString()

    // let decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
    // decrypted = decrypted.toString(CryptoJS.enc.Utf8);

    // console.log(`Criptografado: ${encrypted}`);
    // console.log(`Descriptografado: ${decrypted}`);

    return encrypted
}