import { createSlice } from "@reduxjs/toolkit"

export const userInfoSlice = createSlice({
    name: 'userInfoToolkit',
    initialState: {
        managerInfo: null,
        primaryBalance: 0,
        walletBalances: null,
        userServices: null,
        signupStep: 0,
        isAepsSdkEnable: 0,
        isMatmSdkEnable: 0
    },
    reducers: {
        setManagerInfo(state, action) {
            state.managerInfo = action.payload
        },
        setPrimaryBalance(state, action) {
            state.primaryBalance = action.payload
        },
        setWalletBalances(state, action) {
            state.walletBalances = action.payload
        },
        setUserServices(state, action) {
            state.userServices = action.payload
        },
        setSignupStep(state, action) {
            state.signupStep = action.payload
        },
        setIsAepsSdkEnable(state, action) {
            state.isAepsSdkEnable = action.payload
        },
        setIsMatmSdkEnable(state, action) {
            state.isMatmSdkEnable = action.payload
        }
    }
})

export const userInfoActions = userInfoSlice.actions

export default userInfoSlice.reducer