// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiUrls, statusCode } from '@api/Auth/config'
import { getRequest } from '../../../@apis/Auth/httpService'


export const getData = createAsyncThunk('datatables/getData', async params => {

    let type = ''
    if (params.type !== '' && params.type !== null && params.type !== undefined) {
        type = `type=${params.type}&`
    }

    const response = await getRequest({ url: `${apiUrls.validationSuite.datatableTxn}?${type}page=${params.page}&per_page=${params.perPage}&columns=id&order=desc&search=${params.q}` })

    let tableData = []
    let totalPages = 0
    if (response.data.code === statusCode.success) {
        tableData = response.data.data.data
        totalPages = response.data.data.total
    }
    // return { allData: response.data.data.data, data: {}, totalPages: response.data.data.total, params }
    return { allData: tableData, data: {}, totalPages, params }
})


export const datatablesSlice = createSlice({
    name: 'datatables',
    initialState: {
        data: [],
        total: 1,
        params: {},
        allData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
            state.allData = action.payload.allData
            state.total = action.payload.totalPages
        })
    }
})

export default datatablesSlice.reducer
